var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" }, on: { click: _vm.clickBody } },
    [
      _c("heads"),
      _c("div", { staticClass: "help-top" }, [
        _c(
          "div",
          { staticStyle: { width: "1200px", margin: "auto", height: "363px" } },
          [
            _c("div", { staticClass: "help-me" }, [
              _c("div", { staticClass: "excuse-me" }, [
                _vm._v("您好，请问需要什么帮助？")
              ]),
              _c(
                "div",
                {
                  staticClass: "search-input",
                  attrs: { id: "search" },
                  on: {
                    mouseover: _vm.searchResultOver,
                    mouseleave: _vm.searchResultLeave
                  }
                },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { height: "50px" },
                      attrs: { placeholder: "搜索问题" },
                      model: {
                        value: _vm.keyword,
                        callback: function($$v) {
                          _vm.keyword = $$v
                        },
                        expression: "keyword"
                      }
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: { click: _vm.search },
                        slot: "append"
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "search-result",
                      staticStyle: { display: "none" }
                    },
                    [
                      _vm.isResult
                        ? _c("span", { staticClass: "search-no-content" }, [
                            _vm._v("暂无相关结果")
                          ])
                        : _vm._e(),
                      _vm._l(_vm.searchList, function(item, key) {
                        return _c(
                          "span",
                          {
                            key: key,
                            staticClass: "search-content",
                            on: {
                              click: function($event) {
                                return _vm.searchResult(item.classId)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.title) +
                                "\n            "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ]),
            _c("img", { staticClass: "img-help", attrs: { src: _vm.imgHelp } })
          ]
        )
      ]),
      _c("div", { staticClass: "workIcon" }, [
        _c(
          "div",
          {
            staticClass: "work",
            on: {
              click: function($event) {
                return _vm.helpCenter("login")
              }
            }
          },
          [
            _c("img", { staticClass: "imgIcon", attrs: { src: _vm.img1 } }),
            _c(
              "a",
              {
                staticClass: "workText",
                attrs: { href: "javascript:void(0);" }
              },
              [_vm._v("登录注册\n      ")]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "work marginLeft",
            on: {
              click: function($event) {
                return _vm.helpCenter("beike")
              }
            }
          },
          [
            _c("img", { staticClass: "imgIcon", attrs: { src: _vm.img2 } }),
            _c(
              "a",
              {
                staticClass: "workText",
                attrs: { href: "javascript:void(0);" }
              },
              [_vm._v("备课\n      ")]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "work marginLeft",
            on: {
              click: function($event) {
                return _vm.helpCenter("homework")
              }
            }
          },
          [
            _c("img", { staticClass: "imgIcon", attrs: { src: _vm.img3 } }),
            _c(
              "a",
              {
                staticClass: "workText",
                attrs: { href: "javascript:void(0);" }
              },
              [_vm._v("任务\n      ")]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "work marginLeft",
            on: {
              click: function($event) {
                return _vm.helpCenter("jiaocai")
              }
            }
          },
          [
            _c("img", { staticClass: "imgIcon", attrs: { src: _vm.img4 } }),
            _c(
              "a",
              {
                staticClass: "workText",
                attrs: { href: "javascript:void(0);" }
              },
              [_vm._v("数字教材\n      ")]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "work marginLeft",
            on: {
              click: function($event) {
                return _vm.helpCenter("jiaocan")
              }
            }
          },
          [
            _c("img", { staticClass: "imgIcon", attrs: { src: _vm.img5 } }),
            _c(
              "a",
              {
                staticClass: "workText",
                attrs: { href: "javascript:void(0);" }
              },
              [_vm._v("数字教参\n      ")]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "work marginTop",
            on: {
              click: function($event) {
                return _vm.helpCenter("tiku")
              }
            }
          },
          [
            _c("img", { staticClass: "imgIcon", attrs: { src: _vm.img6 } }),
            _c(
              "a",
              {
                staticClass: "workText",
                attrs: { href: "javascript:void(0);" }
              },
              [_vm._v("题库\n      ")]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "work marginLeft marginTop",
            on: {
              click: function($event) {
                return _vm.helpCenter("bigtest")
              }
            }
          },
          [
            _c("img", { staticClass: "imgIcon", attrs: { src: _vm.img7 } }),
            _c(
              "a",
              {
                staticClass: "workText",
                attrs: { href: "javascript:void(0);" }
              },
              [_vm._v("大数据测评\n      ")]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "work marginLeft marginTop",
            on: {
              click: function($event) {
                return _vm.helpCenter("problem")
              }
            }
          },
          [
            _c("img", { staticClass: "imgIcon", attrs: { src: _vm.img8 } }),
            _c(
              "a",
              {
                staticClass: "workText",
                attrs: { href: "javascript:void(0);" }
              },
              [_vm._v("常见问题\n      ")]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "work marginLeft marginTop",
            on: {
              click: function($event) {
                return _vm.helpCenter("quyuManange")
              }
            }
          },
          [
            _c("img", { staticClass: "imgIcon", attrs: { src: _vm.img9 } }),
            _c(
              "a",
              {
                staticClass: "workText",
                attrs: { href: "javascript:void(0);" }
              },
              [_vm._v("区域管理员\n      ")]
            )
          ]
        )
      ]),
      _c("foots")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }