import _ from 'lodash';

export const tucao = data => {
  // 腾讯吐槽产品ID
  const productId = window.config.txProductId;
  const form = document.createElement('form');
  form.id = 'form';
  form.name = 'form';
  form.target = '_blank';
  document.body.appendChild(form);
  // 设置相应参数
  _.each(data, (val, key) => {
    const input = document.createElement('input');
    input.type = 'text';
    input.name = key;
    input.value = val;
    // 将该输入框插入到 form 中
    form.appendChild(input);
  });
  // form 的提交方式
  form.method = 'POST';
  // form 提交路径
  form.action = `https://support.qq.com/product/${productId}`;
  // 对该 form 执行提交
  form.submit();
  // 删除该 form
  document.body.removeChild(form);
};
export function test() {}
