<template>
  <div style="width:100%;"
    @click="clickBody">
    <!-- 帮助 -->
    <heads />
    <div class="help-top">
      <div style="width: 1200px;margin: auto;height: 363px;">
        <div class="help-me">
          <div class="excuse-me">您好，请问需要什么帮助？</div>
          <div id="search"
            class="search-input"
            @mouseover="searchResultOver"
            @mouseleave="searchResultLeave">
            <el-input v-model="keyword"
              placeholder="搜索问题"
              style="height:50px;">
              <el-button slot="append"
                icon="el-icon-search"
                @click="search" />
            </el-input>
            <div class="search-result"
              style="display: none;">
              <span v-if="isResult"
                class="search-no-content">暂无相关结果</span>
              <span v-for="(item, key) in searchList"
                :key="key"
                class="search-content"
                @click="searchResult(item.classId)">
                {{item.title}}
              </span>
            </div>
          </div>
        </div>
        <img :src="imgHelp"
          class="img-help">
      </div>
    </div>
    <div class="workIcon">
      <div class="work"
        @click="helpCenter('login')">
        <img :src="img1"
          class="imgIcon">
        <a href="javascript:void(0);"
          class="workText">登录注册
        </a>
      </div>
      <div class="work marginLeft"
        @click="helpCenter('beike')">
        <img :src="img2"
          class="imgIcon">
        <a href="javascript:void(0);"
          class="workText">备课
        </a>
      </div>
      <div class="work marginLeft"
        @click="helpCenter('homework')">
        <img :src="img3"
          class="imgIcon">
        <a href="javascript:void(0);"
          class="workText">任务
        </a>
      </div>
      <div class="work marginLeft"
        @click="helpCenter('jiaocai')">
        <img :src="img4"
          class="imgIcon">
        <a href="javascript:void(0);"
          class="workText">数字教材
        </a>
      </div>
      <div class="work marginLeft"
        @click="helpCenter('jiaocan')">
        <img :src="img5"
          class="imgIcon">
        <a href="javascript:void(0);"
          class="workText">数字教参
        </a>
      </div>
      <div class="work marginTop"
        @click="helpCenter('tiku')">
        <img :src="img6"
          class="imgIcon">
        <a href="javascript:void(0);"
          class="workText">题库
        </a>
      </div>
      <div class="work marginLeft marginTop"
        @click="helpCenter('bigtest')">
        <img :src="img7"
          class="imgIcon">
        <a href="javascript:void(0);"
          class="workText">大数据测评
        </a>
      </div>
      <div class="work marginLeft marginTop"
        @click="helpCenter('problem')">
        <img :src="img8"
          class="imgIcon">
        <a href="javascript:void(0);"
          class="workText">常见问题
        </a>
      </div>
      <div class="work marginLeft marginTop"
        @click="helpCenter('quyuManange')">
        <img :src="img9"
          class="imgIcon">
        <a href="javascript:void(0);"
          class="workText">区域管理员
        </a>
      </div>
    </div>
    <!-- 常见问题和反馈 -->
    <!-- <div class="question">
      <div class="question1">
        <div class="question-title">给我们的产品提建议吧</div>
        <div class="question-desc">查看常见问题 反馈用户建议</div>
        <div class="question-send">
          <a href="javascript:void(0);"
            class="question-link"
            @click="commonQuestion">提建议</a>
          <a href="javascript:void(0);"
            class="question-link margin-left-16"
            @click="feedBack">意见反馈</a>
        </div>
      </div>
    </div> -->
    <!-- <contant /> -->
    <foots />
  </div>
</template>
<script>
import _ from 'lodash';
import { getToken } from 'util';
import { tucao } from 'util/tucao';
import heads from './common/head';
import foots from './common/foot';
// import contant from './common/contant';
import imgHelp from '../assets/login/help.svg';
import img1 from '../assets/login/group1.svg';
import img2 from '../assets/login/group2.svg';
import img3 from '../assets/login/group3.svg';
import img4 from '../assets/login/group4.svg';
import img5 from '../assets/login/group5.svg';
import img6 from '../assets/login/group6.svg';
import img7 from '../assets/login/group7.svg';
import img8 from '../assets/login/group8.svg';
import img9 from '../assets/login/group9.png';

import { userPhotoGet, articleyGet } from './api.js';

export default {
  components: {
    heads,
    foots,
    // contant,
  },
  data() {
    return {
      // 图片
      imgHelp,
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
      img8,
      img9,
      // 关键词
      keyword: '',
      // 搜索内容
      searchList: [],
      // 是否有搜索结果
      isResult: false,
      // 点击搜索为true
      isSearch: false,
    };
  },
  mounted() {
    window.addEventListener('keydown', (event) => {
      if (event.keyCode === 13) {
        this.search();
        return false;
      }
    });
  },
  created() { },
  methods: {
    /* 搜索问题 */
    search() {
      // 搜索结果
      const title = document.querySelector('.search-result');
      title.style.display = 'block';
      if (!this.keyword) {
        this.isResult = true;
        return false;
      }
      // 搜索(后台请求)
      articleyGet({ keyword: _.trim(this.keyword, ' ') }).then(({ data, err }) => {
        if (!err) {
          const { data: list } = data;
          this.searchList = list;
          this.isResult = _.isEmpty(this.searchList);
        }
      });
    },
    /* 搜索结果 */
    searchResult(classId) {
      const title = document.querySelector('.search-result');
      title.style.display = 'none';
      window.open(`#/help/${classId}`, '_blank');
    },
    /* 鼠标移入搜索内容 */
    searchResultOver() {
      this.isSearch = false;
    },
    /* 鼠标离开搜索内容 */
    searchResultLeave() {
      this.isSearch = true;
    },
    /* 取消搜索结果 */
    clickBody() {
      // 搜索结果
      if (this.isSearch) {
        const title = document.querySelector('.search-result');
        title.style.display = 'none';
      }
    },
    /* 帮助中心 */
    helpCenter(type) {
      let isType = '1';
      let classId = '';
      switch (type) {
        case 'login':
          classId = '8d59fbd5259042989e0aaa0b6e108bf4';
          break;
        case 'beike':
          classId = '50576ae57c6d4c9a9f8e608b4e1ef480';
          break;
        case 'homework':
          classId = '63f6d1e9a0784f889b9ce5cfbfc538e4';
          break;
        case 'jiaocai':
          classId = 'dde12ddb0f9742079aae8f1fc9cd6afd';
          break;
        case 'jiaocan':
          classId = 'dde12ddb0f9742079aae8f1fc9cd6afd';
          break;
        case 'tiku':
          classId = '6b3406e72dcd4880b58cd22b01f2c66c';
          break;
        case 'bigtest':
          classId = 'c5b949bef8034fbdaaea50e34d53a9eb';
          break;
        case 'problem':
          isType = '4';
          classId = '4d1eb10343134688916c25ff8babdbec';
          break;
        case 'quyuManange':
          classId = 'c9333f862b6e497e9828dc1a9adc598d';
          break;
        default:
          break;
      }
      if (classId) {
        window.open(`#/help/${classId}?isType=${isType}`, '_blank');
      }
    },
    /* 常见问题 */
    commonQuestion() {
      window.open(window.config.txFeedback, '_blank');
    },
    /* 问题反馈 */
    feedBack() {
      getToken().then((res) => {
        if (res) {
          const { user: { username, avatar } = {} } = this.$store.state.app;
          // 根据id获取用户头像
          userPhotoGet({ id: avatar }).then(({ data = {}, err }) => {
            if (!err) {
              const { url = '' } = _.head(data) || {};
              const tocaoData = {
                // nickname,avatar,openid 必填
                nickname: username,
                avatar: url,
                openid: username,
              };
              tucao(tocaoData);
            }
          });
        } else {
          window.open(window.config.txNologinUrl, '_blank');
        }
      });
    },
  },
};
</script>
<style>
#search .el-input--small .el-input__inner {
  width: 466px;
  height: 50px !important;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e5e5;
}
</style>

<style lang="less" scoped>
.help-top {
  width: 100%;
  height: 363px;
  margin-top: 64px;
  padding-top: 142px;
  background: linear-gradient(180deg, rgba(253, 254, 255, 1) 0%, rgba(241, 248, 255, 1) 100%);
}
.excuse-me {
  font-size: 42px;
  padding-top: 88px;
  color: #000;
}
.search-input {
  margin-top: 48px;
}
.help-me {
  width: 504px;
  height: 363px;
  float: left;
}
.img-help {
  width: 577px;
  height: 363px;
  float: right;
}
.workIcon {
  width: 1200px;
  height: 224px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}
.work {
  width: 218px;
  height: 100px;
  background: #fff;
  border: 1px solid #e8e8e8;
  float: left;
}
.work:hover {
  cursor: pointer;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.marginTop {
  margin-top: 20px;
}
.marginLeft {
  margin-left: 20px;
}
.imgIcon {
  width: 70px;
  height: 70px;
  margin-top: 14px;
  margin-left: 14px;
  float: left;
}
a:hover {
  color: #1890ff;
}
.workText {
  height: 25px;
  line-height: 25px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 18px;
  margin-top: 39px;
  display: inline-block;
}

/* 常见问题和反馈 */
.question {
  width: 100%;
  height: 550px;
  margin-top: 160px;
  background: url('../assets/login/wentifankui.png') no-repeat 100% 100%;
}
.question1 {
  width: 100%;
  height: 550px;
  opacity: 0.8;
  background: linear-gradient(135deg, rgba(113, 187, 255, 1) 0%, rgba(22, 88, 255, 1) 100%);
}
.question-title {
  width: 600px;
  text-align: center;
  font-size: 48px;
  color: #ffffff;
  padding-top: 172px;
  margin: auto;
}
.question-desc {
  width: 300px;
  text-align: center;
  font-size: 24px;
  color: #fff;
  margin: auto;
  margin-top: 16px;
}
.question-send {
  width: 380px;
  text-align: center;
  margin: auto;
  margin-top: 48px;
}
.question-link {
  display: inline-block;
  width: 180px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 20px;
}
.question-link:hover {
  color: #fff;
}
.margin-left-16 {
  margin-left: 16px;
}
/* 搜索结果 */
.search-result {
  width: 519px;
  max-height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-top: 8px;
}
.search-content {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  width: 503px;
  padding-left: 16px;
  color: #595959;
  font-size: 14px;
}
.search-content:hover {
  color: #1890ff;
  background: #fafafa;
  cursor: pointer;
}
.search-no-content {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  width: 503px;
  padding-left: 16px;
  color: #595959;
  font-size: 14px;
}
/*滚动条样式*/
.search-result::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.search-result::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
</style>
